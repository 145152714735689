// assets
import admin from '../assets/images/icons/admin.svg';
import customer from '../assets/images/icons/custumer.svg';
import compose from '../assets/images/icons/compose.svg';
import os from '../assets/images/icons/os.svg';
import ipPool from '../assets/images/icons/ip-pool.svg';
import helpdesk from '../assets/images/icons/helpdesk.svg';
import location from '../assets/images/icons/location.svg';
import pricing from '../assets/images/icons/pricing.svg';
import setting from '../assets/images/icons/setting.svg';
import vm from '../assets/images/icons/vm.svg';
import overview from '../assets/images/icons/overview.svg';
import log from '../assets/images/icons/log.svg';
import billing from '../assets/images/icons/billing-2.svg';
import redeemCode from '../assets/images/icons/redeem-code.svg';
import network from '../assets/images/icons/network.svg';
import storage from '../assets/images/icons/icon_storage.svg';
import status from '../assets/images/icons/info-status-outline.svg';

import adminActive from '../assets/images/icons/admin-active.svg';
import composeActive from '../assets/images/icons/compose-active.svg';
import customerActive from '../assets/images/icons/customer-active.svg';
import osActive from '../assets/images/icons/os-active.svg';
import ipPoolActive from '../assets/images/icons/ip-pool-active.svg';
import helpdeskActive from '../assets/images/icons/helpdesk-active.svg';
import locationActive from '../assets/images/icons/location-active.svg';
import pricingActive from '../assets/images/icons/pricing-active.svg';
import settingActive from '../assets/images/icons/setting-active.svg';
import vmActive from '../assets/images/icons/vm-active.svg';
import overviewActive from '../assets/images/icons/overview-active.svg';
import logActive from '../assets/images/icons/log-active.svg';
import billingActive from '../assets/images/icons/billing-2-active.svg';
import redeemCodeActive from '../assets/images/icons/redeem-code-active.svg';
import networkActive from '../assets/images/icons/network-active.svg';
import storageActive from '../assets/images/icons/icon_storage-active.svg';
import statusActive from '../assets/images/icons/info-status.svg';

// ==============================|| MAIN MENU & DOCUMENTATION MENU ITEMS ||============================== //
const useCwHelpdesk = process.env.REACT_APP_USE_CW == 'true' ?? false;
let Menu = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        icon: overview,
        iconActive: overviewActive,
        breadcrumbs: false
    },
    {
        id: 'admin',
        title: 'Admin',
        type: 'item',
        url: '/admin',
        icon: admin,
        iconActive: adminActive,
        breadcrumbs: false
    },
    {
        id: 'customer',
        title: 'Customer',
        type: 'collapse',
        url: '/customer',
        icon: customer,
        iconActive: customerActive,
        children: [
            {
                id: 'prepaid',
                parent_id: 'customer',
                title: 'Prepaid',
                type: 'item',
                url: '/customer/prepaid'
            },
            {
                id: 'pending_invitation',
                parent_id: 'customer',
                title: 'Pending Invitation',
                type: 'item',
                url: '/customer/pending_invitation'
            },
            {
                id: 'postpaid',
                parent_id: 'customer',
                title: 'Postpaid',
                type: 'item',
                url: '/customer/postpaid'
            },
            {
                id: 'identity',
                parent_id: 'customer',
                title: 'Identity Verification',
                type: 'item',
                url: '/customer/verification'
            }
        ]
    },
    {
        id: 'compose-email',
        title: 'Compose Email',
        type: 'item',
        url: '/compose-email',
        icon: compose,
        iconActive: composeActive,
        breadcrumbs: false
    },
    {
        id: 'vm',
        title: 'Virtual Machines',
        type: 'collapse',
        icon: vm,
        iconActive: vmActive,
        children: [
            {
                id: 'instance',
                parent_id: 'vm',
                title: 'Instance',
                type: 'item',
                url: '/instance'
            },
            {
                id: 'migration',
                parent_id: 'vm',
                title: 'Migration',
                type: 'item',
                url: '/migration'
            }
        ]
    },
    {
        id: 'network',
        title: 'Network',
        type: 'collapse',
        icon: network,
        iconActive: networkActive,
        children: [
            {
                id: 'public_network',
                parent_id: 'network',
                title: 'Public Network',
                type: 'item',
                url: '/network/public_network'
            },
            {
                id: 'private_network',
                parent_id: 'network',
                title: 'Private Network',
                type: 'item',
                url: '/network/private_network'
            }
        ]
    },
    {
        id: 'block-storage',
        title: 'Block Storage',
        type: 'item',
        url: '/block-storage',
        icon: storage,
        iconActive: storageActive,
        breadcrumbs: false
    },
    {
        id: 'billing',
        title: 'Billing',
        type: 'collapse',
        icon: billing,
        iconActive: billingActive,
        children: [
            {
                id: 'topup_history',
                parent_id: 'billing',
                title: 'Top Up History',
                type: 'item',
                url: '/billing/topup_history'
            },
            {
                id: 'postpaid_invoice',
                parent_id: 'billing',
                title: 'Postpaid Invoice',
                type: 'item',
                url: '/billing/postpaid_invoice'
            },
            {
                id: 'reports',
                parent_id: 'billing',
                title: 'Reports',
                type: 'item',
                url: '/billing/reports'
            }
        ]
    },
    {
        id: 'operating-system',
        title: 'System',
        type: 'collapse',
        icon: os,
        iconActive: osActive,
        children: [
            {
                id: 'os',
                parent_id: 'operating-system',
                title: 'Operating System',
                type: 'item',
                url: '/operating-system/os'
            },
            {
                id: 'app',
                parent_id: 'operating-system',
                title: 'Application',
                type: 'item',
                url: '/operating-system/app'
            }
        ]
    },
    {
        id: 'ip-pool',
        title: 'IP Pool',
        type: 'item',
        url: '/ip-pool',
        icon: ipPool,
        iconActive: ipPoolActive,
        breadcrumbs: false
    },
    {
        id: 'location',
        title: 'Location',
        type: 'item',
        url: '/location',
        icon: location,
        iconActive: locationActive,
        breadcrumbs: false
    },
    {
        id: 'pricing',
        title: 'Pricing',
        type: 'collapse',
        icon: pricing,
        iconActive: pricingActive,
        children: [
            {
                id: 'component',
                parent_id: 'pricing',
                title: 'Component',
                type: 'item',
                url: '/pricing/component'
            },
            {
                id: 'package',
                parent_id: 'pricing',
                title: 'Package',
                type: 'item',
                url: '/pricing/package'
            },
            {
                id: 'price',
                parent_id: 'pricing',
                title: 'Price',
                type: 'item',
                url: '/pricing/price'
            }
        ]
    },
    {
        id: 'gift',
        title: 'Gift',
        type: 'collapse',
        icon: redeemCode,
        iconActive: redeemCodeActive,
        children: [
            {
                id: 'coupon',
                parent_id: 'gift',
                title: 'Topup Coupon',
                type: 'item',
                url: '/gift/coupon'
            },
            {
                id: 'redeem_code',
                parent_id: 'gift',
                title: 'Redeem Code',
                type: 'item',
                url: '/gift/redeem_code'
            }
        ]
    },
    {
        id: 'app_setting',
        title: 'App Setting',
        type: 'collapse',
        icon: setting,
        iconActive: settingActive,
        children: [
            {
                id: 'settings',
                parent_id: 'app_setting',
                title: 'General',
                type: 'item',
                url: '/app-setting/settings'
            },
            {
                id: 'tier',
                parent_id: 'app_setting',
                title: 'Tier',
                type: 'item',
                url: '/app-setting/tier'
            },
            {
                id: 'reward',
                parent_id: 'app_setting',
                title: 'Rewards',
                type: 'item',
                url: '/app-setting/reward'
            },
            {
                id: 'firewall_rules',
                parent_id: 'app_setting',
                title: 'Firewall Rules',
                type: 'item',
                url: '/app-setting/firewall_rules'
            }
        ]
    },
    {
        id: 'log',
        title: 'Log',
        type: 'collapse',
        icon: log,
        iconActive: logActive,
        children: [
            {
                id: 'activity_logs',
                parent_id: 'log',
                title: 'Activity Logs',
                type: 'item',
                url: '/log/activity_logs'
            },
            {
                id: 'login_trails',
                parent_id: 'log',
                title: 'Login Trails',
                type: 'item',
                url: '/log/login_trails'
            }
        ]
    },
    {
        id: 'info',
        title: 'Info',
        type: 'collapse',
        icon: status,
        iconActive: statusActive,
        children: [
            {
                id: 'announcement',
                parent_id: 'info',
                title: 'Announcement',
                type: 'item',
                url: '/info/announcement'
            },
            {
                id: 'release_notes',
                parent_id: 'info',
                title: 'Release Notes',
                type: 'item',
                url: '/info/release_notes'
            }
        ]
    }
];

if (!useCwHelpdesk) {
    Menu = [
        ...Menu.slice(0, 10),
        {
            id: 'helpdesk',
            title: 'Ticket/Helpdesk',
            type: 'item',
            url: '/helpdesk',
            icon: helpdesk,
            iconActive: helpdeskActive,
            breadcrumbs: false
        },
        ...Menu.slice(10)
    ];
}

function MainMenu(userRole) {
    if (userRole === 'Admin') {
        return {
            id: 'main-menu',
            type: 'group',
            children: [
                Menu.find((item) => item.id === 'dashboard'),
                Menu.find((item) => item.id === 'admin'),
                Menu.find((item) => item.id === 'customer'),
                Menu.find((item) => item.id === 'compose-email'),
                Menu.find((item) => item.id === 'billing'),
                Menu.find((item) => item.id === 'vm'),
                Menu.find((item) => item.id === 'network'),
                Menu.find((item) => item.id === 'block-storage'),
                Menu.find((item) => item.id === 'helpdesk'),
                Menu.find((item) => item.id === 'gift'),
                Menu.find((item) => item.id === 'operating-system'),
                Menu.find((item) => item.id === 'app_setting'),
                Menu.find((item) => item.id === 'log'),
                Menu.find((item) => item.id === 'info')
            ]
        };
    } else if (userRole === 'Admin Billing') {
        return {
            id: 'main-menu',
            type: 'group',
            children: [
                Menu.find((item) => item.id === 'customer'),
                Menu.find((item) => item.id === 'compose-email'),
                Menu.find((item) => item.id === 'billing'),
                Menu.find((item) => item.id === 'helpdesk'),
                Menu.find((item) => item.id === 'app_setting'),
                Menu.find((item) => item.id === 'gift'),
                Menu.find((item) => item.id === 'info')
            ]
        };
    } else if (userRole === 'Admin Sales') {
        return {
            id: 'main-menu',
            type: 'group',
            children: [
                Menu.find((item) => item.id === 'customer'),
                Menu.find((item) => item.id === 'compose-email'),
                Menu.find((item) => item.id === 'billing'),
                Menu.find((item) => item.id === 'helpdesk'),
                Menu.find((item) => item.id === 'gift'),
                Menu.find((item) => item.id === 'info')
            ]
        };
    } else if (userRole === 'Admin Support') {
        return {
            id: 'main-menu',
            type: 'group',
            children: [
                Menu.find((item) => item.id === 'customer'),
                Menu.find((item) => item.id === 'compose-email'),
                Menu.find((item) => item.id === 'billing'),
                Menu.find((item) => item.id === 'vm'),
                Menu.find((item) => item.id === 'network'),
                Menu.find((item) => item.id === 'block-storage'),
                Menu.find((item) => item.id === 'helpdesk'),
                Menu.find((item) => item.id === 'info')
            ]
        };
    } else if (userRole === 'Super Admin') {
        // Return all menu items
        return {
            id: 'main-menu',
            type: 'group',
            children: Menu
        };
    } else if (userRole === 'Best Admin') {
        // Return all menu items
        return {
            id: 'main-menu',
            type: 'group',
            children: Menu
        };
    } else {
        return null;
    }
}

export default MainMenu;
